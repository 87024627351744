import React, {Fragment,useState} from 'react';
import { NavLink} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import config  from '../config.js';
import { makeStyles, Theme,useTheme, createStyles ,withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  TextField,
  Button,
  Paper,
  Grid,
  Avatar,
  Container,
  AppBar,
  Box,
  List,
  IconButton,
  OutlinedInput,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Menu,
  MenuItem,
  RadioGroup,
  Radio,
  Icon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  Select,
  Input,
  InputLabel,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  Fab,
  Tab,
  Tabs,
  InputBase,
  Chip,
  LinearProgress,
  ButtonBase,
  FormLabel,
  FormGroup,
  Toolbar,
  FormControlLabel,
  FormHelperText,
  Divider
} from '@material-ui/core';
import $ from './loader.js';
import {Row,Col} from 'react-bootstrap';
import { isMobile } from "react-device-detect";


import ChipInput from 'material-ui-chip-input';
import MaterialTable from 'material-table';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from "@material-ui/pickers";


import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}


const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const TabStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));


const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  }
})(LinearProgress);


export  function MTabs(props) {
  const classes = TabStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          {props.list && props.list.map((a,b)=>(<AntTab key={b} label={<Typography variant="subtitle2" {...a11yProps(b)}>{a.name}</Typography>} />))}
        </AntTabs>
        {props.list && props.list.map((a,b)=>(
       <TabPanel key={b} title={a.title} value={value} index={b}><Box p={3}>{a.component}</Box></TabPanel>))}
      </div>
    </div>
  );
}

export  function MMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button  onClick={handleClick}>{props.text}</Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >{props.sub_menu && props.sub_menu.map((a,b)=>(<MenuItem>{a.text}</MenuItem>))}
      </Menu>
    </div>
  );
}

export function MLoading (props){
     const classes = useStyles()
    return (<div className="page-loader-wrapper">
    <div className="loader">
    <div className="logo msg margin0 text-center">
      <img src={config.logo} width="200px" alt=""/>
      <ColorLinearProgress className="col-centered" style={{width:'100px',marginTop:'30px'}}/>
    </div>
    <div className="preloader">
   </div> </div></div>);
}
export function MDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
       <Button style={props.style} className={props.buttonVisible == false ?'hide': ''} variant="contained" color={props.color?props.color:'primary'} onClick={props.onClick}>{props.text}</Button>
      <Dialog transitionDuration={0} open={props.show} fullWidth={true} onClose={props.onClose} scroll="body" aria-labelledby="form-dialog-title" maxWidth={props.size} maxWidth={props.size} fullScreen={fullScreen}>
        {props.title && <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>}
        <DialogContent>{props.loading && <div className="container_center">
        <CircularProgress className="container_loading" color="secondary" />
        </div>}
        {props.previewOnly ? <>{props.body}<DialogActions>{props.extra && props.extra}</DialogActions></> :<form onSubmit={(e)=>props.onSubmit(e,props.form_id)} id={props.form_id}>{props.body}<DialogActions><Button size="medium" onClick={props.onClose} variant="contained" size="medium"> Cancel </Button> <Button size="medium" type="submit" variant="contained" color="primary" size="medium"> Save </Button>{props.extra && props.extra} </DialogActions></form>}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function LinkActivity(props){
  return (<><Chip icon={<DescriptionIcon/>}  label={props.label}  onClick={()=>window.open(props.link)} onDelete={props.onDelete}/></>);
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width:'100%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export function MInput(props){
   var val = (typeof props.updatevalue == 'undefined') ?{}:props.updatevalue;
   var resultval = Object.keys(val).length > 0 ? (typeof val[props.name] != 'undefined' ?val[props.name]:null):null;
  const label=props.label;
  const [date,setDate] = React.useState(resultval ? new Date(resultval):null);
  const [select,setSelect] = React.useState(resultval ? resultval:'');
  const [radio,setRadio] = React.useState(resultval ? resultval:'');
  const [check,setCheck] = React.useState(resultval ? resultval:false);
  const [chip,setChip] = React.useState(resultval ? resultval:[]);
  const chipAdd=(a,b)=>{
    const data = chip;
    data.push(a);
    setChip(data)
    props.onrefchange({name:props.name,value:data})
  }
  const submit=(e)=>{
    let key = e.which || e.keyCode;
    if (key == 13) {
      props.onrefchange({value:input.current.value,name:input.current.name})
    }
  }
  const chipDelete=(a,b)=>{
    var data = chip;
    data = data.filter(i=> i != a);
    setChip(data)
    props.onrefchange({name:props.name,value:data})
  }
    var elem=''
    let input = React.createRef();
    const addprops = Object.keys(props).reduce((a,b)=> (b != 'onrefchange' ? {...a,[b]:props[b]}:a),{});

    switch (props.data_type) {
       case 'date':
       elem =   isMobile ? <TextField type="date"  {...addprops}  margin="normal" defaultValue={resultval}  inputRef={input} onBlur={()=>props.onrefchange(input.current)} fullWidth={true} /> :<MDatepicker  strictCompareDates={true}  {...addprops} autoOk={true} invalidDateMessage={null} clearable {...addprops}  inputRef={input} value={date}  onChange={(e)=>{setDate(e);props.onrefchange({name:props.name,value:e})}} label={label}/>;
      break;
      case 'datetime':
        elem =  isMobile ? <TextField type="datetime-local"  {...addprops}  margin="normal" defaultValue={resultval}  inputRef={input} onBlur={()=>props.onrefchange(input.current)} fullWidth={true} /> :<MDateTimepicker  strictCompareDates={true}  {...addprops} autoOk={true} invalidDateMessage={null} clearable {...addprops}  inputRef={input} value={date}  onChange={(e)=>{setDate(e);props.onrefchange({name:props.name,value:e})}} label={label}/>;
        break;
      case 'multi_input':
        elem = <ChipInput value={chip}  onAdd={chipAdd} onDelete={chipDelete}  fullWidth defaultValue={resultval ? resultval:[]} label={props.label} {...addprops} />;
        break;
      case 'select':
        const rand = "select_"+Math.ceil(Math.random()*993984394);
        let helperText = addprops.helperText ?  addprops.helperText:'';
        delete addprops['helperText'];
        elem = <FormControl variant={props.variant}  fullWidth={true} className="MuiTextField-root MuiFormControl-marginNormal">
          <InputLabel htmlFor={rand}>{props.label}</InputLabel>
            <Select renderValue={props.renderValue}
       required={props.required}
         fullWidth={true}  value={select} onChange={(e)=>{setSelect(e.target.value);props.onrefchange(e.target)}} name={props.name} input={props.variant == 'outlined' ? <OutlinedInput {...addprops} value={select} name={props.name} id={rand}/> : <Input  name={props.name} {...addprops}  id={rand} {...addprops}  value={select}/>} className={props.className}><MenuItem value="">
             <em>None</em>
             </MenuItem>{props.list.map((a,b)=>(<MenuItem key={b} value={a.value}>{a.text}</MenuItem> ))}
             </Select>
             <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
        break;
      case 'checkbox':
            elem = <FormGroup><br/>
                    <FormLabel component="label">{props.label}</FormLabel>
                    <FormControlLabel control={<Checkbox {...addprops} checked={check} onChange={(e)=>{setCheck(e.target.checked);props.onrefchange({name:props.name,value:e.target.checked})}}  />} label={props.text} />
                </FormGroup>
        break;
      case 'radio':
        elem = <FormControl component="fieldset">
            <RadioGroup aria-label="position" name={props.name} row>
            {props.list && props.list.map((a,b)=>(<FormControlLabel key={b} value={a.value}  control={<Radio color="primary" checked={a.value == radio} onChange={(e)=>{setRadio(e.target.value);props.onrefchange(e.target)}}  />} label={<b>{a.text}</b>} labelPlacement={props.position} />))}
            </RadioGroup>
          </FormControl>
        break;
      default:
       elem = <TextField  {...addprops} onKeyDown={(e)=>submit(e)} margin="normal" defaultValue={resultval} inputRef={input} onBlur={()=>props.onrefchange(input.current)} fullWidth={true} />
    }
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>{elem}</MuiPickersUtilsProvider>

}


export function MSelect(props) {
  var addprops = props;
  const rand = "select_"+Math.ceil(Math.random()*993984394);
  let helperText = addprops.helpertext ?  addprops.helpertext:'';
  return   <FormControl variant={addprops.variant}  fullWidth={true} className="MuiTextField-root MuiFormControl-marginNormal"><InputLabel htmlFor={rand}>{addprops.label}</InputLabel><Select renderValue={addprops.renderValue}
 required={addprops.required}
   fullWidth={true}  value={addprops.value||''} onChange={addprops.onChange} name={addprops.name} input={addprops.variant == 'outlined' ? <OutlinedInput {...addprops} name={addprops.name} id={rand}/> : <Input  name={addprops.name} {...addprops} id={rand} {...addprops} />} className={addprops.className}><MenuItem value=""> <em>None</em></MenuItem>{addprops.list.map((a,b)=>( <MenuItem key={b} value={a.value}>{a.text}</MenuItem> ))}</Select><FormHelperText>{helperText}</FormHelperText></FormControl>;
}

export function MInputControlled(props) {


    var elem="";
    var val = (typeof props.updatevalue == 'undefined') ?{}:props.updatevalue;
    var resultval = Object.keys(val).length > 0 ? (typeof val[props.name] != 'undefined' ?val[props.name]:''):'';
    const label=props.label;
    if (props.data_type == 'date') {
      resultval = resultval=="" ? null:new Date(resultval);
      elem = <MDatepicker autoOk invalidDateMessage={null} clearable {...props}  value={resultval}  label={label}/>;
    }else if (props.data_type == 'datetime') {
        resultval = resultval=="" ? null:new Date(resultval);
        elem = <MDateTimepicker autoOk invalidDateMessage={null} clearable {...props}  value={resultval}  label={label}/>;
    }else if(props.data_type == 'select'){
      elem = <MSelect name={props.name} required={props.required}   value={resultval} list={props.list} label={props.label} {...props}/>
    }else if (props.data_type == 'multi_input') {
      elem =  <ChipInput fullWidth value={resultval ? resultval:[]} label={props.label} {...props} />
    }else if (props.data_type == 'checkbox') {
      elem = <FormGroup><br/><FormLabel component="label">{props.label}</FormLabel><FormControlLabel control={<Checkbox checked={typeof(resultval) != 'boolean' ? false:resultval}  {...props} />} label={props.text} /></FormGroup>
    }else if (props.data_type == 'radio') {
       elem = <FormControl component="fieldset"> <RadioGroup aria-label="position" name={props.name} row> {props.list && props.list.map((a,b)=>(<FormControlLabel key={b} value={a.value}  control={<Radio color="primary" checked={a.value == resultval} onChange={props.onChange} />} label={<b>{a.text}</b>} labelPlacement={props.position} />))}</RadioGroup> </FormControl>
    }else{
      elem = <TextField margin="normal" label={label} value={resultval} type="text" fullWidth={true} {...props}  />;
    }
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>{elem}</MuiPickersUtilsProvider>
}

export function MTable(props){
  const action  = typeof(props.action) == 'undefined' ? true : props.action;
  const options  = typeof(props.action) == 'undefined' ? {} : props.options;
  return (<div style={{ maxWidth: '100%' }}>
    <MaterialTable className={props.className}
      columns={props.column && props.column.map(a=> ({...a,title:a.title.toUpperCase()}))}
      data={props.data} actions={action ? [{
            icon: 'delete',
            tooltip: 'Delete',
            onClick: props.onDelete,
            disabled:props.prevent
          },
          rowData => ({
            icon: 'edit',
            tooltip: 'Edit',
            onClick:props.onEdit,
          })
        ]:[]}
        options={{
          actionsColumnIndex: 0,
          exportButton:true,
          exportAllData:true,
          exportFileName:props.fileName,
          defaultSort:'asc',
          grouping:true,
        ...options}}
      title={props.title}  {...props}
    />
  </div>);
}

export function MDatepicker(props){
  return <KeyboardDatePicker
          margin="normal"
          onChange={props.onChange}
          format="dd-MM-yyyy"
          mask="__-__-____"
         {...props}
        />
}
export function UploadProgress(props) {
  return  (props.show ? <><br/><br/>
   <Typography align="left" display="block" variant="button">{props.status}</Typography>
   <LinearProgress color="primary" />
   </>:'');
}
export function MDateTimepicker(props) {
  return <KeyboardDateTimePicker
         ampm={true}
         format="dd-MMM-yyyy hh:mm a"
         {...props}
       />
};
export  function SearchBar(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <InputBase select="true"
        className={classes.input}
        placeholder={props.placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
