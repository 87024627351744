import React from 'react';
import config from '../config.js';
import {MInputControlled,MInput} from '../components/basic_component.js';
import {ajax} from '../components/fun.js';
import swal from 'sweetalert';
import {
        Typography,
        Grid,
        Box,
        Dialog,
        DialogTitle,
        Button,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        CircularProgress,
         } from '@material-ui/core';

        import PersonIcon from '@material-ui/icons/Person';
        import VpnKeyIcon from '@material-ui/icons/VpnKey';



export default class Login extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      modal:true,
      modal_load:false,
      modalobj:{},
      status:'otp',
      loginSubmit:false,
      otpSubmit:false,
      ChangeSubmit:false,
    }
    this.modalobj={};

  }
  inputChange=(a)=>{
    if (a.name) {
      this.modalobj[a.name]= a.value;
    }
  }
  inputControlChange=(e)=>{
      let name =  e.target.name;
      let val =  e.target.value;
     this.setState(state=>{
       state.modalobj[name] = val
       return state;
     },()=>{
        this.modalobj[name] = val
     })
  }
  componentDidMount(){
    document.title="Login"
    this.setState({
      status:this.props.mode
    })
  }
  componentDidUpdate(a,b){
    if(a.mode !== this.props.mode){
      this.setState({
        status:this.props.mode
      })
    }
  }
  loginSubmit=(e)=>{
    e.preventDefault();
    let username  = this.modalobj.user_name;
    let password  = this.modalobj.password;
    if (username.trim() && password.trim()) {
        this.setState({
           loginSubmit:true,
        })
        ajax({
          url:`/login`,
          data:{username,password}
        },(a)=>{
          try {
            if (a.status) {
                this.setState({
                  session:true,
                },()=>{
                  window.location.href=window.location.origin
                })
                swal({
                  title:'Success',
                  text:'Login Successfully',
                  icon:'success'
                })
            }else{
              swal({
                title:'Not Allowed',
                text:a.msg,
                icon:'error'
              })
            }
          } catch (e) {

          } finally {
            this.setState({
              loginSubmit:false
            })
          }

        })
    }else {
      swal({
        title:'Not Allowed',
        text:'Username/password not empty',
        icon:'error'
      })
    }
  }
  login=()=>{
    return <List component="form" onSubmit={this.loginSubmit} aria-label="main mailbox folders" subheader={<Typography component="div" align="center">
    <img src={config.logo} alt="logo" width={150} /></Typography>}>
      <ListItem >
        <ListItemIcon style={{top:'18px',left:'20px',position:'relative'}}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText >
        <MInputControlled autoFocus={true} label="Email ID/Mobile Number" onChange={this.inputControlChange} updatevalue={this.state.modalobj} name="user_name" />
        </ListItemText>
      </ListItem>
      <ListItem >
        <ListItemIcon style={{top:'18px',left:'20px',position:'relative'}}>
          <VpnKeyIcon />
        </ListItemIcon>
        <ListItemText>
        <MInputControlled label="Password" type="password" onChange={this.inputControlChange} updatevalue={this.state.modalobj} name="password" />
        </ListItemText>
      </ListItem>
      <ListItem >
      <ListItemIcon style={{top:'18px',left:'20px',position:'relative'}}><span></span>
      </ListItemIcon>
        <ListItemText>
        <Button type="submit"  elevation={0}  style={{marginTop:'10px'}} color="secondary" fullWidth={true} variant="contained" disabled={this.state.loginSubmit}>{this.state.loginSubmit ? <CircularProgress color="primary" thickness={5}  size={25}/> : 'Sign IN'}</Button>
        </ListItemText>
      </ListItem>
      <ListItem alignItems="center">
        <ListItemIcon style={{top:'18px',left:'20px',position:'relative'}}><span></span></ListItemIcon>
        <ListItemText>  <Typography display="block" component="div" align="center"  variant="body2">Forget Password ? </Typography></ListItemText>
      </ListItem>
    </List>
  }
  password=(e)=>{
      e.preventDefault();
      let password  = this.modalobj.password;
      let cpassword = this.modalobj.cpassword;
      if (cpassword === password) {
        this.setState({
           ChangeSubmit:true,
        })
          ajax({
            url:`/login/change`,
            data:{ password,cpassword }
          },(a)=>{
            try {
              if (a.status) {
                swal({
                  title:'Success',
                  text:'Password Added Successfully',
                  icon:'success'
                })
                  window.location.href=window.location.origin
              }else{
                swal({
                  title:'Try again',
                  text:a.msg,
                  icon:'warning'
                })
              }
            } catch (e) {

            } finally {
              this.setState({
                 ChangeSubmit:false,
              })
            }
          })
      }else{
        swal({
          title:'Not Allowed',
          text:'Password & confirm Password not Equal',
          icon:'error'
        })
      }
  }
  passwordChange=()=>{
    return <List component="form" onSubmit={this.password} aria-label="main mailbox folders"subheader={<Typography component="div" align="center">
    <img src={config.confirm} alt="confirm" width={100} /></Typography>}>
      <ListItem >
        <ListItemText>
        <Typography component="p" variant="subtitle2" align="center">For first time login Please create the password</Typography>
        <MInput type="password" autoFocus={true} label="Password"  onrefchange={this.inputChange} updatevalue={this.state.modalobj} name="password" />
        </ListItemText>
      </ListItem>
      <ListItem >
        <ListItemText>
        <MInput type="password" label="Confirm Password"  onrefchange={this.inputChange} updatevalue={this.state.modalobj} name="cpassword" />
        </ListItemText>
      </ListItem>
      <ListItem >
        <ListItemText  className="text-center">
          <Button type="submit"  elevation={0}  color="secondary" fullWidth={true} variant="contained" disabled={this.state.ChangeSubmit}>{this.state.ChangeSubmit ? <CircularProgress color="primary" thickness={5}  size={25}/> : 'Submit'}</Button>
        </ListItemText>
      </ListItem>
    </List>;
  }
  OtpVerify=(e)=>{
    e.preventDefault();
    let otp =  this.modalobj.otp;
    if (otp.trim()) {
      this.setState({
         otpSubmit:true,
      })
        ajax({
          url: `/login/session/verify`,
          data:{otp}
        },(a)=>{
          try {
            if (a.status) {
              this.setState({
                status:'change'
              })
            }else if(!a.status){
              swal({
                title:'Not Allowed',
                text:"Invalid OTP",
                icon:'error'
              })
            }
          } catch (e) {

          } finally {
            this.setState({
               otpSubmit:false,
            })
          }
        })
    }
  }
  otp=()=>{
    return <List component="form" onSubmit={this.OtpVerify} aria-label="main mailbox folders"subheader={<Typography component="div" align="center">
    <img src={config.otp} alt="otp" width={100} /></Typography>}>
      <ListItem >
        <ListItemText>
        <Typography  variant="button" display="block" align="center">{this.props.name}</Typography>
        <Typography component="p" variant="caption" align="center">Enter the OTP received from your email</Typography>
        {// <Typography component="p" variant="subtitle2" align="center">{this.props.mobile}</Typography>
        // <Typography  component="p" variant="caption" align="center">---OR Email---</Typography>
      }<Typography  component="p" variant="subtitle2" align="center">{this.props.email}</Typography>
        <br/>
        <MInput autoFocus={true} inputProps={{className:'text-center'}}  placeholder="Enter 6 digit one Time Password" onrefchange={this.inputChange} updatevalue={this.state.modalobj} name="otp" />
        </ListItemText>
      </ListItem>
      <ListItem >
        <ListItemText  className="text-center">  <Button type="submit"  elevation={0} color="secondary" fullWidth={true} variant="contained" disabled={this.state.otpSubmit}>{this.state.otpSubmit ? <CircularProgress color="primary" thickness={5}  size={25}/> : 'verify'}</Button>
        </ListItemText>
      </ListItem>
    </List>;
  }
  Container=()=>{
    return  <Dialog fullWidth={true} transitionDuration={0} open={this.props.session} maxWidth="md" >
      <DialogTitle disableTypography style={{padding:0}}>{this.state.loading && <div className="container_center">
      <CircularProgress className="container_loading" color="secondary" />
      </div>}
      <Grid container spacing={0}>
        <Grid item md={6} sm={12}  style={{backgroundColor:'#795548'}} >
          <Box p={5} >

          </Box>
        </Grid>
        <Grid item md={6}  sm={12} >
          <Box p={5} style={{paddingLeft:(this.state.status === 'login' ? '20px':'40px')}}>
            {this.state.status === 'login' && this.login()}
            {this.state.status === 'otp' && this.otp()}
            {this.state.status === 'change' && this.passwordChange()}
          </Box>
        </Grid>
      </Grid>
      </DialogTitle>
    </Dialog>
  }
  render() {
    return (this.Container())
  }
}
