import React,{useEffect} from 'react';
import { withStyles,emphasize } from '@material-ui/core/styles';
import config from '../../config.js';
import { BrowserRouter as Router, Route, NavLink, Switch,withRouter } from "react-router-dom"
import {MMenu,SearchBar,MTable,LinkActivity,MTabs,MLoading,MInputControlled,UploadProgress} from '../../components/basic_component.js';
import {ajax,upload,ObjectFind,TimeRender} from '../../components/fun.js';
import moment from 'moment';
import swal from 'sweetalert';

import {
        CssBaseline,
        Toolbar,
        IconButton,
        Breadcrumbs,
        Paper,
        Typography,
        Grid,
        Chip,
        Tooltip,
        Link,
        Button,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        Divider,
        Container,
        Avatar,
        AppBar,
        Menu,CircularProgress,
        MenuItem,Badge} from '@material-ui/core';

import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


function UploadElem(props) {
  const [uploading,setUpload] = React.useState(false);
  useEffect(a=>{
    return ()=>{
      setUpload(false)
    };
  })
  function uploadFile(e,a){
          if(props.file_name){
          setUpload(true);
          const data = e.target.files[0];
          var formdata =  new FormData();
          formdata.append('file',data)
          formdata.append('doc_id',props.doc_id)
          formdata.append('profile_id',props.profile_id)
          formdata.append('filename',props.file_name.replace(/\(|\)|\/|\\|&|\s+/g,'_').replace().toString());
          upload({
            data:formdata
          },(a)=>{
            try {
              if (a.status) {
                swal({
                  title: "Upload SuccessFully",
                  icon: "success",
                })
               props.uploaded(props.file_name,a.id,props.doc_id)
              }else{
                swal({title:'Not Allowed',text:a.msg,icon:'error'});
              }
            } catch (e) {

            } finally {
              setUpload(false)
            }
          })
        }

    }

  return <>{uploading ? <UploadProgress show={true} status="Uploading..." /> : <MInputControlled InputLabelProps={{ shrink: true, }}  label={props.file_name} variant="outlined" onChange={(e)=>uploadFile(e,props.file_name)}  type="file"   name={props.file_name} />}</>;
}


export default class Document extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      load:true,
      modalobj:{},
      data:this.props.data,
      table_load:false,
    }
    this.title="Upload Documents";
    this.column=[
      {title:'Doc Name',field:'doc_id',render:(a)=>a.doc_id.doc_name.toUpperCase()},
      {title:'Preview',field:'upload_id',render:(a)=>a.upload_status ? <LinkActivity label="File" link={'/api/upload/'+a.upload_id}/> : <Typography variant="body2">Un Available</Typography>},
      {title:'Status',field:'time',render:(a)=>a.upload_status ? this.timeAdd(config.upload_status,a,'upload_status','time') : <UploadElem file_name={a.doc_id.doc_name} doc_id={a._id} profile_id={this.props.session.user_id} upload_status={a.upload_status} uploaded={this.Uploaded}/>}
    ]
  }
  Uploaded=(key,uploaded_id,row_id)=>{
    this.setState(state=>{
      let ind = state.data.findIndex((a,b)=> a._id == row_id);
      state.data[ind]['upload_status'] = true;
      state.data[ind]['upload_id']=uploaded_id;
      state.data[ind]['time'] =  Date.now()
      return state;
    })
  }
  timeAdd=(arr,cur,key,add)=>{
      let new_arr = JSON.parse(JSON.stringify(arr));
      if (cur[add]) {
        new_arr =  new_arr.find((a,b)=> a.value==cur[key]);
        return <Tooltip title={TimeRender(cur[add])} interactive><Chip size="small" label={new_arr.text} className={new_arr.className+' chip'} /></Tooltip>
      }else{
        return '';
      }
  }
  componentDidMount(){
    document.title = "Documents";
  }
  componentDidUpdate(a,b){
    console.log(this.props.data);
  }
  Container=()=>{
      return <MTable action={false} options={{exportButton:false,pageSize:20}} title={this.title}  column={this.column} data={this.state.data} isLoading={this.state.table_load}/>;
  }
  render() {
    return this.Container();
  }
}
