import React from 'react';
import { withStyles,emphasize } from '@material-ui/core/styles';
import config from '../config.js';
import { BrowserRouter as Router, Route, NavLink, Switch,withRouter } from "react-router-dom"
import {MMenu,SearchBar,LinkActivity,MTabs,MLoading} from '../components/basic_component.js';
import {ajax,ObjectFind} from '../components/fun.js';
import $ from '../components/loader.js';
import moment from 'moment';
import swal from 'sweetalert';

import {
        CssBaseline,
        Toolbar,
        IconButton,
        Breadcrumbs,
        Paper,
        Typography,
        Grid,
        Chip,
        Link,
        Button,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        Divider,
        Container,
        Avatar,
        AppBar,
        Menu,CircularProgress,
        MenuItem,Badge} from '@material-ui/core';

import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//pages
import Resume from './sub_home/resume.js';
import Document from './sub_home/document.js';

      const classes = theme => ({
        toolbar: {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        toolbarTitle: {
          flex: 1,
        },
        toolbarSecondary: {
          justifyContent: 'space-between',
          overflowX: 'auto',
        },
        marginTop:{
          marginTop:'60px',
          height:'100px',
          zIndex:1,
          borderRadius:0,
        },
        toolbarLink: {
          padding: theme.spacing(1),
          flexShrink: 0,
          textDecoration:'none'
        },
        mainFeaturedPost: {
          position: 'relative',
          backgroundColor: theme.palette.grey[800],
          height:'100px',
          borderRadius:0,
          boxShadow:'none',
          color: theme.palette.common.white,
          marginBottom: theme.spacing(4),
          backgroundImage:'url('+config.back+')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.6)',
        },
        gridContainer:{
          padding: theme.spacing(3, 2),
        },
        mainFeaturedPostContent: {
          position: 'relative',
          marginTop:'40px',
          padding: theme.spacing(3),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
          },
          width:'80%'
        },
        breadcrumb: {
          padding: theme.spacing(1),

        },
        mainGrid: {
          marginTop: theme.spacing(3),
        },
        card: {
          display: 'flex',
        },
        cardDetails: {
          flex: 1,
        },
        cardMedia: {
          width: 160,
        },
        markdown: {
          ...theme.typography.body2,
          padding: theme.spacing(3, 0),
        },
        sidebarAboutBox: {
          padding: theme.spacing(2),
          backgroundColor: theme.palette.grey[200],
        },
        sidebarSection: {
          marginTop: theme.spacing(3),
        },
        whiteBar:{
          backgroundColor:'#fff',
          color:'#333'
        },
        footer: {
          backgroundColor: theme.palette.background.paper,
          marginTop: theme.spacing(8),
          padding: theme.spacing(6, 0),
        },
      });
      const StyledBreadcrumb = withStyles(theme => ({
        root: {
          backgroundColor: theme.palette.grey[100],
          height: theme.spacing(3),
          color: theme.palette.grey[800],
          fontWeight: theme.typography.fontWeightRegular,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
          },
          '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
          },
        },
      }))(Chip)

class Home extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      load:true,
      modalobj:{},
    };
    this.tabs=[]
  }
  componentDidMount(){
    document.title = "Dashboard"
    this.getContent();
  }
  getContent=()=>{
    var id=this.props.session.user_id;
    if (id) {
      ajax({
        url:`/profile/${id}`,
        method:'get'
      },(a)=>{
         if (a.status && a.data) {
          this.setState({
            session:true,
            modalobj:a.data,
            load:false,
          })
        }
      })
    }
  }

  Tabs=()=>{
    let tabs =[{
      name:'Resume',
      component:()=><Resume session={this.props.session} data={this.state.modalobj ? this.state.modalobj.profile_resume :''} />
    },{
      name:'Documents',
      component:()=><Document session={this.props.session} data={this.state.modalobj ? this.state.modalobj.profile_doc:[]} />
    }];
    return <MTabs list={tabs}/>;
  }
  previewBox=()=>{
    return <><Grid container spacing={2}>
          <Grid item xs={12}  md={2}>
             <img className="profile_image" alt="complex" src={config.dummy_user} />
          </Grid>
          <Grid item xs={12} md={10}>
          <Typography variant="h6"  className="width-full uppercase" paragraph>{this.state.modalobj.profile_name||''} </Typography>
           <Typography component={'span'} display="block" paragraph>
           <Chip size="small" color="secondary"  className="chip" label={ObjectFind(config.gender,'value',this.state.modalobj.profile_gender) ? ObjectFind(config.gender,'value',this.state.modalobj.profile_gender)['text']:''}/>
             <Chip size="small" className="chip" avatar={<Avatar><WorkIcon/></Avatar>} label={this.state.modalobj.profile_exp_yr+'.'+this.state.modalobj.profile_exp_m+' Yrs'}/>
             <Chip size="small" className="chip" avatar={<Avatar>CTC</Avatar>} label={this.state.modalobj.profile_cctc+' Lks'}/>
             <Chip size="small" className="chip" color="primary" avatar={<Avatar><PersonIcon/></Avatar>} label={this.state.modalobj.profile_role}/>
           </Typography>

           <Grid item  xs={12} sm>
             <Grid container  spacing={2}>
                   <Grid item  xs={12} sm={6}>
                   <table className="table_padding" style={{tableLayout:'fixed'}}>
                    <tbody>
                     <tr>
                     <th className="uppercase" width="40%">Email <b className="pull-right">:</b></th>
                     <td> {this.state.modalobj.profile_email}</td>
                     </tr>
                     <tr>
                     <th className="uppercase">Mobile No <b className="pull-right">:</b></th>
                     <td> <Chip size="small" variant="outlined" color="primary" label={this.state.modalobj.profile_mobile}/></td>
                     </tr>
                     <tr>
                       <th className="uppercase">D.O.B <b className="pull-right">:</b></th>
                       <td> {moment(this.state.modalobj.profile_dob).format('DD-MM-YYYY')}</td>
                     </tr>
                     <tr>
                       <th className="uppercase">Education <b className="pull-right">:</b></th>
                       <td> {this.state.modalobj.profile_edu}</td>
                     </tr>
                     <tr>
                       <th className="uppercase">Industry <b className="pull-right">:</b></th>
                       <td> {this.state.modalobj.profile_industry}</td>
                     </tr>
                     </tbody>
                   </table>
                   </Grid>
                   <Grid item  xs={12} sm={6}>
                   <table className="table_padding" style={{tableLayout:'fixed'}}>
                    <tbody>
                     <tr>
                     <th className="uppercase" width="40%">Designation <b className="pull-right">:</b></th>
                     <td> {this.state.modalobj.profile_des}</td>
                     </tr>
                     <tr>
                     <th className="uppercase">Cur.Org<b className="pull-right">:</b></th>
                     <td>{this.state.modalobj.profile_corg}</td>
                     </tr>
                     <tr>
                       <th className="uppercase">App ID<b className="pull-right">:</b></th>
                       <td>{this.state.modalobj.appid}</td>
                     </tr>
                     <tr>
                       <th className="uppercase">Func Area <b className="pull-right">:</b></th>
                       <td> {this.state.modalobj.profile_fun_area}</td>
                     </tr>
                     <tr>
                       <th className="uppercase">Cibil <b className="pull-right">:</b></th>
                       <td> {this.state.modalobj.profile_cibil}</td>
                     </tr>
                     </tbody>
                   </table>
                   </Grid>
             </Grid>
           </Grid>
          </Grid>
          <br/>
          <br/>
          <br/>
    </Grid></>
  }
  banner=()=>{
    return <Paper  className={this.props.classes.mainFeaturedPost}>
      <Container maxWidth="lg">
      <Grid container  className={this.props.classes.marginTop}  direction="row"
justify="flex-start"
alignItems="center">
    <Typography variant="h5" color="inherit" style={{zIndex:1,textTransform:'uppercase'}} className="text-white">{this.state.modalobj.profile_name}</Typography>
      </Grid>
      </Container>
    </Paper>;
  }
  QuickLinks=()=>{
    return <Paper elevation={0} style={{width:'100%'}}   >
      <List component="nav"  aria-label="main mailbox folders">
        <Typography variant="button" style={{padding:'5px'}} display="block" align="center">Quick Links</Typography>
        <Divider variant="middle"/>
       <ListItem key={1} button>
         <ListItemText primaryTypographyProps={{variant:'subtitle2'}} primary="Inbox" />
       </ListItem>
       <ListItem key={2}  button>
         <ListItemText  primaryTypographyProps={{variant:'subtitle2'}} primary="Drafts" />
       </ListItem>
        </List>
      </Paper>
  }
  Path=()=>{
    return <><Paper elevation={0} className={this.props.classes.breadcrumb}> <Breadcrumbs aria-label="breadcrumb"> <StyledBreadcrumb component="a" href="#" label="Home" icon={<HomeIcon fontSize="small" />} /> </Breadcrumbs> </Paper> <br/></>;
  }
  render() {
    return (
      <>{this.state.load ? <MLoading/> : <>{this.state.modalobj.profile_name && <>{this.banner()}<Container maxWidth="lg">
        <main>{this.Path()}<Paper elevation={0} className={this.props.classes.gridContainer} >
        <Grid container direction="row">
        {this.previewBox()}
        </Grid>
        </Paper>
        <Grid container  spacing={3}>
            <Grid item md={12}>
            </Grid>
            <Grid item md={9} xs={12}>
              <Paper elevation={0}  className={this.props.classes.gridContainer} >
              {this.Tabs()}
              </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
              {this.QuickLinks()}
            </Grid>
          </Grid>
        </main>
      </Container></>}</>}</>
    );
  }
}

export default withStyles(classes)(Home)
