import React from 'react';
import $ from './loader.js';
import Grow from '@material-ui/core/Grow';
import Snackbar from '@material-ui/core/Snackbar';
import swal from 'sweetalert';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';


// import './linkJS/bootstrap-datepicker.min.js'
export function formatBytes(a,b){if(0==a)return"0 Bytes";var c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]};

export function ajax(req,callback) {
  const url     = req.url ? req.url : '/query';
  const method  = req.method ? req.method:'POST';
  const headers = req.headers ? req.headers:{'Accept': 'application/json',
         'Content-Type': 'application/json'};
  return fetch('/api'+url,{method,headers,body:JSON.stringify(req.data)}).then(res=>res.json()).then((a)=> (typeof(a.session) != "undefined" && !a.session) ? swal({title:a.title,text:a.msg,icon:'error'}).then(()=>window.location.reload()) : callback(a));
}
export function upload(req,callback) {
  const url     = '/upload/insert';
  const data    = req.data ? req.data:[];
  return fetch('/api'+url,{method:'POST',body:data}).then(res=>res.json()).then((a)=> (typeof(a.session) != "undefined" && !a.session) ? swal({title:a.title,text:a.msg,icon:'error'}).then(()=>window.location.reload()) : callback(a));
}
export function ObjectFind(a,key,select) {
  return  a.find(i=>i[key] === select);
}
export function lookupArray(array) {
  return Object.assign({},...array.map(i=> ({[i.value]:i.text})));
}
export function optionArray(array,val,text) {
  return array.map(i=> ({value:i[val],text:i[text]}));
}

export function TimeRender(a) {
  return moment(a).format('DD-MMM-YYYY hh:mm A');
}
export function Status(arr,b) {
   const a =  arr[b];
   return <Chip color={a ? 'primary':'error'} label={a ? 'Active':'Closed'} size="small" icon={<Icon>{a ? 'check':''}</Icon>} />;
}
export function ColumnRender(arr,key,value) {
  return value[key] && Object.keys(value).length > 0 ? (arr.find((i)=> i.value === value[key])['text']||''):'';
}
export function capitalize(a) {
  return a ? a.toString().split('')[0].toUpperCase() + a.slice(1):'';
}
export function dateRender(a) {
  return moment(a).format('DD-MM-YYYY');
}
export function Toast(props){
    return <Snackbar open={props.open} TransitionComponent={Grow} ContentProps={{ 'aria-describedby': 'message-id', }} message={<span id="message-id">{props.content}</span>} />;
}
export function href(link) {
   window.open(link)
}

// export function notify(msg,variant){
//     variant = variant ? variant:'';
//     enqueueSnackbar(msg,{variant});
// }
export function title(a,sub_menu) {

  document.title=capitalize(a);
  if (!sub_menu) {
    let path = window.location.pathname;
    $('.menu-list').find('li.active').removeClass('active');
    $('.menu-list').find('a[href="'+path+'"]').closest('li').addClass('active');
  }
}
export function sub_title(a) {
  document.title=capitalize(a);
  let path = window.location.pathname;
  $('.sub_menu').find('li.active').removeClass('active');
  $('.sub_menu').find('a[href="'+path+'"]').closest('li').addClass('active');
}
export function uppercase(str) {
  return str ? str.toUpperCase():str;
}
export function menu_list(a) {
  // document.title=capitalize(a);
  // let path = window.location.pathname;
  $('.menu-list > li.active').removeClass('active');
  $('.menu-list > li[path="'+a+'"]').addClass('active');
}
