import React from 'react';
//component
import {MLoading} from '../components/basic_component.js';
import '../assets/css/App.css';
import {ajax} from '../components/fun.js';
import { SnackbarProvider } from 'notistack';

//Admin route (Main route)
import MAdmin from './Admin.js';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      session:[],
      success:false,
      load:false,
    }
  }
  componentDidMount(){
      this.getSession();
  }

  getSession = () => {
    ajax({url:'/session'},(a)=>{
      this.setState({
        session:a.session_arr,
        success:a.success,
        load:true
      });
      if (window.sessionStorage && a.success) {
        window.sessionStorage.setItem('SESSION', JSON.stringify(a.session_arr));
      }
    });
  }

  render(){
    return ( this.state.load ? <MAdmin success={this.state.success} session={this.state.session}/> : <MLoading/>)
  }
}


export default () => (
    <SnackbarProvider maxSnack={3}  classes={{ variantSuccess: '#333' }}  anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}><App/></SnackbarProvider>
);
