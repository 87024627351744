
window.$ = window.jQuery = require('jquery')
var $=window.$;
var jQuery=window.$;
const moment = require('moment');

Array.prototype.serializeArrayToObj = function () {
return this.reduce((a, b) => (a[b.name] = b.value, a), {})
}

Array.prototype.createObj = function (obj,objkey,delimeter,expect) {
obj[objkey]={};
   this.forEach(function (a) {
                if(a.indexOf(delimeter)>-1){
                  obj[objkey][a.split('_').pop()] = obj[a];
                  if(expect){
                    if(a.indexOf(expect) == -1){
                      delete  obj[a];
                    }
                  }else{
                    delete  obj[a];
                  }
                }
                });
  return obj;
}

$.expr[':'].contains = function(a, i, m) {
  return jQuery(a).text().toUpperCase()
      .indexOf(m[3].toUpperCase()) >= 0;
};

Number.prototype.currency = function(format) {
  if (format == 'symbol') {
    return this.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
  });
  }else{
    return this.toLocaleString('en-IN');
  }
};

//string
String.prototype.validateNaN =  function () {
return !isNaN(this) ? this : 0;
}
String.prototype.parseFloatNaN = function () {
var str = this;
return !isNaN(parseFloat(str)) ? parseFloat(str) : 0;
}
Number.prototype.parseFloatNaN = function () {
var str = this.toString();
return !isNaN(parseFloat(str)) ? parseFloat(str) : 0;
}
function time_convert(format,str) {
  str = str.toString();
var ret="";
if(format == 'date'){
  ret  = moment(str,'DD-MM-YYYY').format("YYYY-MM-DD");
}else{
  ret  = moment(str,'DD-MM-YYYY hh:mm A').format("YYYY-MM-DD HH:MM:SS");
}
return ret;
}
function datefromdb(format,str) {
  var ret="";
  if(format == 'date'){
    ret  = moment(str,'YYYY-MM-DD').format("DD-MM-YYYY");
  }else{
    ret  = moment(str,'YYYY-MM-DD HH:mm:ss');
  }
    return ret;
}

function datetodb(a) {
  Object.keys(a).forEach(function(i) {
    if (typeof a[i] == 'object') {
      a[i] = datetodb(a[i]);
    } else if ((i.indexOf('date') > -1) || (i.indexOf('dob') > -1)) {
      a[i] = a[i] ? moment(a[i], 'DD-MM-YYYY').format("YYYY-MM-DD") : '0000-00-00';
    }
  })

  return a;
}


function hashadd(val) {
  window.location.hash='#'+val;
}


function datetodbfilter(a) {
  Object.keys(a).forEach(function(i) {
    if (typeof a[i] == 'object') {
      a[i] = datetodb(a[i]);
    } else if ((i.indexOf('date') > -1) || (i.indexOf('dob') > -1)) {
      a[i] = a[i] ? moment(a[i], 'DD-MM-YYYY').format("YYYY-MM-DD") : "";
    }
  })
  return a;
}


String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.validateNaN = function () {
return !isNaN(this) ? this : 0;
}

String.prototype.lastString = function (delimeter) {
return this.toString().split(delimeter).pop()
}

$.fn.extend({
serializeElement:function () {
                           if($(this).find('table').length>0){
                             var arr = {};
                              var copy_table = $(this);
                              var tb =[];
                              $(copy_table).find('table').each(function () {
                                $(this).find('tbody').find('tr').each(function () {
                                 tb.push($(this).serializeElementSimple());
                               })
                             })
                             arr['table']=tb;
                            var ft ={};
                            $(copy_table).find('table').each(function () {
                                Object.assign(ft,$(this).find('tfoot').serializeElementSimple())
                            })
                              arr['normal'] = $(copy_table).find('div').not(":has(table)").serializeElementSimple();
                              arr['normal'] = Object.assign(arr['normal'],ft);
                           }else{
                             var arr = $(this).serializeElementSimple();
                           }
                            return arr;
                        },
                      msg:function (a) {
                          $(this).find('.loader_text').text(a)
                      },
                      simple_form_insert:function (a) {
                           var obj = Object.keys(a);
                           var that = this;
                           $(this).find('input,select,textarea').each(function () {
                             $(this).val("");
                             var val= a[$(this).attr('name')];
                             if($(this).hasClass('datepicker')){
                               $(this).datepicker('setDate',val);
                             }else{
                               $(this).val(val);
                             }
                           })
                         },
                      Val:function () {
                        if($(this).is('select')){
                          return $(this).find('option:selected').val();
                        }
                      },
                        serializeElementSimple: function() {
                          var arr = $(this).find('input,textarea,select').map(function(a, elem) {
                        if($.trim($(elem).attr('name'))){
                          if ($(elem).is('input[type=radio]') || $(elem).is('input[type=checkbox]')) {
                            if($(elem).is(':checked')){
                                return ({[$(elem).attr('name')]:$(elem).attr('data-value') ?  $.trim($(elem).attr('data-value')) :$.trim($(elem).val())});
                            }else{
                              if($(elem).is('input[type=checkbox]')){
                              return ({[$(elem).attr('name')]:""});
                            }
                            }
                          }else if ($(this).hasClass('datepicker')) {
                              return ({[$(elem).attr('name')]:($(elem).val().trim() ?  $(elem).val().toString().split('-').reverse().join('-') :"")});
                          }
                          else{

                            return ({[$(elem).attr('name')]:$(elem).attr('data-value') ?  $.trim($(elem).attr('data-value')) :$.trim($(elem).val())});
                          }
                        }
                        }).get();

                        arr = arr.reduce((a,b)=> (a[Object.keys(b)[0]] = Object.values(b)[0],a),{});
                        return arr;
                        },
requireValidate:function () {
                  var status= true;
                  var nodelist = $(this).find('input[required],textarea[required],select[required]');
                  for (var i = 0; i < nodelist.length; i++) {
                    nodelist.eq(i).closest('.form-group').removeClass('has-error');
                    if(nodelist.eq(i).attr('type') != 'radio'){
                    if(!$.trim(nodelist.eq(i).val()) || (nodelist.eq(i).attr('data-autocomplete') && !nodelist.eq(i).hasClass('expect_required') && !nodelist.eq(i).attr('data-value'))){
                       nodelist.eq(i).focus();
                       return false;
                       break;
                     }
                   }
                  }
                  return status;
                },
errorShow:function () {
  $(this).each(function () {
    $(this).closest('.form-group').addClass('has-error');
  })
}
})


export default $;
