import React from 'react';
import { withStyles,emphasize } from '@material-ui/core/styles';
import config from '../../config.js';
import { BrowserRouter as Router, Route, NavLink, Switch,withRouter } from "react-router-dom"
import {MMenu,SearchBar,MTable,LinkActivity,UploadProgress,MTabs,MLoading,MInputControlled} from '../../components/basic_component.js';
import {ajax,ObjectFind,upload} from '../../components/fun.js';
import moment from 'moment';
import swal from 'sweetalert';

import {
        CssBaseline,
        Toolbar,
        IconButton,
        Breadcrumbs,
        Paper,
        Typography,
        Grid,
        Chip,
        Link,
        Button,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        Divider,
        Container,
        Avatar,
        AppBar,
        Menu,CircularProgress,
        MenuItem,Badge} from '@material-ui/core';

import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class Resume extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      load:true,
      data:this.props.data,
      table_load:false,
      profile_resume:'',
      uploading:false
    }
    this.title="Upload Documents";
    this.column=[
      {title:'S.No',field:'s_no'},
      {title:'Document',field:'doc_name'},
      {title:'Available',field:'doc_upload'},
      {title:'Upload',field:'upload'},
    ]
  }
  componentDidMount(){
    document.title = "Documents";
  }
  uploadFile=(e,a)=>{
        if (!this.state.modalobj.profile_name && !this.modalobj.profile_name) {
          swal({title:'Invalid username',text:'Please Fill the username first',icon:'error'});
        }else if(e.target.files.length == 0){
          swal({title:'No files are selected',text:'Missing Files',icon:'error'});
        }else{
          this.setState({
            uploading:true,
          })
          const data = e.target.files[0];
          var formdata =  new FormData();
          formdata.append('file',data);
          formdata.append('filename',this.state.modalobj.profile_name);
          upload({
            data:formdata
          },(a)=>{
            try {
              if (a.status) {
                swal({
                  title: "Upload SuccessFully",
                  content:(<><b>Note : With in 5 Min this form will not submitted.Uploaded file has removed</b></>),
                  icon: "success",
                })
                let key = 'profile_resume';
                this.setState(state=>{
                  state.modalobj[key] = a.id;
                  return state;
                })
              }else{
                swal({title:'Not Allowed',text:a.msg,icon:'error'});
              }
            } catch (e) {

            } finally {
              this.setState({
                uploading:false,
              })
            }
          })
        }

    }
  Container=()=>{
      return <Grid  container justify="space-between" direction="row" alignItems="center">
      <Grid item xs={6} md={8}>
        <Typography variant="button" align="left">Attach Resume</Typography>
        <Typography variant="body2" align="left">Supporting Fromat : <Typography variant="caption">doc,docx,pdf</Typography> </Typography>
      </Grid>
        <Grid item container xs={6} md={4} justify="flex-end">
        {!this.state.uploading && !this.state.data && <MInputControlled InputLabelProps={{ shrink: true, }}  label="Upload Resume" variant="outlined" onChange={(e)=>this.uploadFile(e,'profile_resume')}  type="file"   name="profile_resume" />}
          <UploadProgress show={this.state.uploading} status="Uploading Resume..." />
          {this.state.data && <><br/><br/><LinkActivity size="medium" label="Resume" link={'/api/upload/'+this.state.data} /></>}
        </Grid>
      </Grid>;
  }
  render() {
    return this.Container();
  }
}
