import logo from './assets/images/logo.png';
import back from './assets/images/back.jpeg';
import otp from './assets/images/lock.jpg';
import confirm from './assets/images/confirm.png';
import dummy_user from './assets/images/dummy_user.png';
var config = {
  company_name: "Beerana",
  logo,
  back,
  dummy_user,
  otp,
  confirm,
  gender: [{
    value: 1,
    text: 'Male'
  }, {
    value: 2,
    text: 'Female'
  }],
  role: [{
    value: 1,
    text: 'Admin'
  }, {
    value: 2,
    text: 'Team Leader'
  }, {
    value: 3,
    text: 'Recuirter'
  }],
  img_arr: ['/src/men/small.png', '/src/men/small.png', '/src/women/small.png'],
  status: [{
    value: true,
    text: 'Active'
  }, {
    value: false,
    text: 'Paused'
  }],
  smtp: [{
    value: 1,
    text: 'Gmail'
  }, {
    value: 2,
    text: 'Zoho'
  }],
  upload_status: [{
      value: true,
      text: 'Available',
      className: 'bg-green'
    },
    {
      value: false,
      text: 'NotAvailable',
      className: 'bg-red'
    }
  ],
}

export default config;
