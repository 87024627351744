import React,{Suspense,lazy,useState}from 'react';
import { withStyles } from '@material-ui/core/styles';
import config from '../config.js';
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom"
import {MMenu,LinkActivity,MLoading} from '../components/basic_component.js';
import {ajax} from '../components/fun.js';
import swal from 'sweetalert';


import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import {
        CssBaseline,
        Toolbar,
        Paper,
        IconButton,
        Typography,
        Grid,
        Popover,
        Chip,
        Hidden,
        Link,
        Button,
        Divider,
        List,
        ListItem,
        ListItemAvatar,
        ListItemSecondaryAction,
        ListItemText,
        Container,
        Avatar,
        AppBar,
        Menu,CircularProgress,
        MenuItem,Badge} from '@material-ui/core';


import Login from '../pages/login'
import Home from '../pages/home'


      const classes = theme => ({
        toolbar: {

        },
        toolbarTitle: {
          flex: 1,
        },
        toolbarSecondary: {
          justifyContent: 'space-between',
          overflowX: 'auto',
        },
        marginTop:{
          marginTop:'50px',
          height:'300px',
          zIndex:1,
        },
        toolbarLink: {
          padding: theme.spacing(1),
          flexShrink: 0,
          textDecoration:'none'
        },
        mainFeaturedPost: {
          position: 'relative',
          backgroundColor: theme.palette.grey[800],
          color: theme.palette.common.white,
          marginBottom: theme.spacing(4),
          backgroundImage:'url('+config.back+')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
        overlay: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.6)',
        },
        mainFeaturedPostContent: {
          position: 'relative',
          minHeight:'350px',
          marginTop:'40px',
          padding: theme.spacing(3),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
          },
          width:'80%'
        },
        mainGrid: {
          marginTop: theme.spacing(3),
        },
        card: {
          display: 'flex',
        },
        cardDetails: {
          flex: 1,
        },
        cardMedia: {
          width: 160,
        },
        markdown: {
          ...theme.typography.body2,
          padding: theme.spacing(3, 0),
        },
        sidebarAboutBox: {
          padding: theme.spacing(2),
          backgroundColor: theme.palette.grey[200],
        },
        sidebarSection: {
          marginTop: theme.spacing(3),
        },
        whiteBar:{
          backgroundColor:'#fff',
          color:'#333',
          padding: theme.spacing(0.3),
        },
        footer: {
          backgroundColor: theme.palette.background.paper,
          marginTop: theme.spacing(8),
          padding: theme.spacing(6, 0),
        },
      });
      function ProfileBar(props) {
        console.log(props);
        const [anchorEl, setAnchorEl] = useState(null);

       const handleClick = event => {
         setAnchorEl(event.currentTarget);
       };

       const handleClose = () => {
         setAnchorEl(null);
       };
       const open = Boolean(anchorEl);
       const id = open ? 'simple-popover' : undefined;
         return(<>{props.session && <><IconButton edge="end" size="small"style={{marginLeft:'10px'}} color="inherit"      onClick={handleClick} >
           <Avatar alt="user_img" src={props.session.user_img ? `/api/upload/thumb/${props.session.user_img}`:config.dummy_user} />
         </IconButton>
         <Popover id={id} elevation={8} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
      <Paper  elevation={0}>
      <List className={classes.root}>
      <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt="user_img" src={props.session.user_img ? `/api/upload/thumb/${props.session.user_img}`:config.dummy_user} />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant={"button"}>{props.session.user_name.toUpperCase()}</Typography>}
        style={{width:'200px'}}
        secondary={
          <>
            <Typography component="div" noWrap={true} variant="body2" className={classes.inline} color="textPrimary" >{props.session.user_email} </Typography>
          </>
        }
      />
      <Divider orientation="vertical" />
      <ListItemSecondaryAction>
      <IconButton edge="end" onClick={()=>window.location.href="/api/logout"}>
      <ExitToAppIcon/>
      </IconButton>
      </ListItemSecondaryAction>
      </ListItem>
      </List>
      </Paper>
      </Popover></>}</>)
      }

      class MAdmin extends React.Component{
        constructor(props) {
          super(props);
          this.state = {
            session:this.props.success,
            load:true,
            loginmode:'login',
            modalobj:{},
            mobile:'',
            name:'',
            email:''
          }
        }
        componentDidMount(){
          this.Session();
          this.header_menu=[
            {
              id:1,
              path:'/',
              name:'Home',
              component:(a)=> <Home {...a} session={this.props.session}/>
            },
            {
              id:1,
              path:'/:id',
              name:'Home',
              component:(a)=> <Home {...a} session={this.props.session}/>
            }
          ]
        }
        Header=()=>{
          return <AppBar  elevation={9} position="fixed" className={this.props.classes.whiteBar}>
          <Container maxWidth="lg">
          <Toolbar className={this.props.classes.toolbar}>
            <NavLink to="/"><img style={{verticalAlign:'bottom'}} alt="logo"  src={config.logo} width="120"/></NavLink>
             <Typography
                component="h2"
                variant="h5"
                color="inherit"
                align="center"
                noWrap
                className={this.props.classes.toolbarTitle}
              >
              </Typography>
            <Toolbar component="nav" variant="dense" className={classes.toolbarTitle}>

            {// {header_menu.map((a,b) => (
            //   <>{a.title && <NavLink key={b} to={a.path} className={classes.toolbarLink} >{a.sub_menu ? <MMenu text={a.title} sub_menu={a.sub_menu}/>: <Button component="a">{a.title}</Button>}</NavLink>}</>
            // ))}
          }
            </Toolbar>
            <Toolbar className={this.props.classes.toolbarSecondary}>
                   {this.state.session ? <><IconButton >
                     <Badge badgeContent={0} color="secondary">
                       <NotificationsIcon />
                     </Badge>
                   </IconButton>
                   <ProfileBar session={this.props.session}/></>:<Button>Login</Button>}
            </Toolbar>
          </Toolbar>
          </Container>
          </AppBar>;
        }
        Session=()=>{
          let id=window.location.pathname.replace('/','');
          if (id) {
            ajax({
              url:`/login/session/${id}`,
            },(a)=>{
              console.log(a);
              if (a.status && a.mode == 'otp') {
                  this.setState({
                    loginmode:a.mode,
                    load:false,
                    mobile:a.userData.mobile,
                    email:a.userData.email,
                    name:a.userData.name
                  })
              }else if (a.status && a.data) {
                this.setState({
                  session:true,
                  modalobj:a.data,
                  load:false,
                })
              }else{
                this.setState({
                  loginmode:a.mode,
                  load:false,
                  session:false,
                })
              }
            })
          }else{
            this.setState({
              load:false
            })
          }
        }
        Container=()=>{
          return <><CssBaseline/>{this.Header()}<Switch>{this.header_menu && this.header_menu.map((a,b)=>(<Route key={b} path={a.path} exact={true} render={(i)=> a.component(i)} />))}</Switch></>;
        }
        render() {
          return (
            <>{this.state.load ? <MLoading/> : (this.state.session ? <Router>{this.Container()}</Router>:<Login mobile={this.state.mobile} email={this.state.email} name={this.state.name} session={!this.state.session} mode={this.state.loginmode} />)}</>
          );
        }
      }


export default withStyles(classes)(MAdmin);
